import { useState } from 'react'
import QRCode from 'react-qr-code'

export default function DeepLinksPage() {
  const [evseCode, setEvseCode] = useState('799998')
  const [unitId, setUnitId] = useState('a59d7571-1c2c-4a33-992d-aeb31abb15f0')
  const [unitCode, setUnitCode] = useState('48c92c9c-d243-4a69-a068-d49dbfda0f45')

  const deepLinks = [
    {
      section: 'Explore',
      links: [
        'foresta://foresta.energy/explore',
        'foresta://foresta.energy/explore/locations/6510b53c-69a9-4b3f-b116-2b64b03d0fd2',
        'foresta://foresta.energy/explore/evses/799998',
      ],
    },
    {
      section: 'Dashboard',
      links: [
        'foresta://foresta.energy/dashboard',
        'foresta://foresta.energy/dashboard/contracts',
        'foresta://foresta.energy/dashboard/contracts/ca5da352-255b-493a-8bbd-ca9e1cf03e6c',
        'foresta://foresta.energy/dashboard/sessions',
        'foresta://foresta.energy/dashboard/sessions/sim-6f76a928-df16-4e31-8387-c78b8a181594',
      ],
    },
    {
      section: 'Host',
      links: [
        'foresta://foresta.energy/host',
        'foresta://foresta.energy/host/contracts',
        'foresta://foresta.energy/host/contracts/cf442ffc-429a-484b-8a26-cd6133794069',
        'foresta://foresta.energy/host/locations/2d6fcb8a-c172-4e52-90d8-ede98c3b66ea',
        'foresta://foresta.energy/host/locations/2d6fcb8a-c172-4e52-90d8-ede98c3b66ea/sessions',
      ],
    },
    {
      section: 'Account',
      links: [
        'foresta://foresta.energy/account',
        'foresta://foresta.energy/account/profile',
        'foresta://foresta.energy/account/vehicles',
        'foresta://foresta.energy/account/notifications',
        'foresta://foresta.energy/account/payment_methods',
        'foresta://foresta.energy/account/payment_methods/transactions',
        'foresta://foresta.energy/account/wallet',
        'foresta://foresta.energy/account/wallet/transactions',
        'foresta://foresta.energy/account/memberships', // partners
        'foresta://foresta.energy/account/membership/f99929c8-feca-4d33-b26c-10d6c820dafe/8d175d8d-60f8-4ecb-898c-5ef96b26c6c4',
        'foresta://foresta.energy/account/host_earnings',
        'foresta://foresta.energy/account/help',
      ],
    },
    {
      section: 'Stripe',
      links: ['http://foresta.energy/return'],
    },
  ]

  return (
    <section className="relative isolate bg-white/0 dark:bg-slate-600/60 py-10 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 space-y-6">
        <div>
          <h1 className="text-4xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
            QR Codes & Deep Links
          </h1>
        </div>

        <div className="space-y-2">
          <h2 className="text-2xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
            QR Code - EV Station
          </h2>
          <div className="w-36 bg-white p-2">
            <QRCode
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`http://foresta.energy/evses/${evseCode}`}
            />
          </div>
          <div className="flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 sm:text-sm border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-950 text-gray-500">
              Evse Code
            </span>
            <input
              type="text"
              value={evseCode}
              onChange={(event) => setEvseCode(event.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-brand focus:border-brand sm:text-sm bg-white dark:bg-white/5 text-gray-900 dark:text-white border-gray-300 dark:border-gray-700"
              placeholder={evseCode}
            />
          </div>
          <a href={`/evses/${evseCode}`} className="text-brand hover:text-brand-dark">
            {`http://foresta.energy/evses/${evseCode}`}
          </a>
        </div>

        <div className="space-y-2">
          <h2 className="text-2xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
            QR Code - Membership
          </h2>
          <div className="w-36 bg-white p-2">
            <QRCode
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`http://foresta.energy/membership/${unitId}/${unitCode}`}
            />
          </div>
          <div className="flex flex-row rounded-md shadow-sm">
            <span className="flex items-center px-3 rounded-l-md border border-r-0 sm:text-sm border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-950 text-gray-500">
              Unit ID
            </span>
            <input
              type="text"
              value={unitId}
              onChange={(event) => setUnitId(event.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-brand focus:border-brand sm:text-sm bg-white dark:bg-white/5 text-gray-900 dark:text-white border-gray-300 dark:border-gray-700"
              placeholder={unitId}
            />
          </div>
          <div className="flex flex-row rounded-md shadow-sm">
            <span className="flex items-center px-3 rounded-l-md border border-r-0 sm:text-sm border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-950 text-gray-500">
              Code
            </span>
            <input
              type="text"
              value={unitCode}
              onChange={(event) => setUnitCode(event.target.value)}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-brand focus:border-brand sm:text-sm bg-white dark:bg-white/5 text-gray-900 dark:text-white border-gray-300 dark:border-gray-700"
              placeholder={unitCode}
            />
          </div>
          <a
            href={`/membership/${unitId}/${unitCode}`}
            className="text-brand hover:text-brand-dark"
          >
            {`http://foresta.energy/membership/${unitId}/${unitCode}`}
          </a>
        </div>

        {deepLinks.map((deepLink, index) => (
          <div key={`section-${index}`} className="space-y-2">
            <h2 className="text-2xl font-bold tracking-tight text-slate-900 dark:text-slate-100">
              {deepLink.section}
            </h2>
            <ul className="space-y-3">
              {deepLink.links.map((link, index) => (
                <li key={`${deepLink.section}-${index}`}>
                  <a href={link} className="text-brand hover:text-brand-dark">
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  )
}
